<template>
  <div class="outer_container" style="overflow: scroll; overflow-y: hidden; margin-top: 100px">
    <carousel-3d controls-visible="true" :controls-prev-html="'&#8249; '" :controls-next-html="'&#8250;'" :controls-width="30" :controls-height="60" :clickable="false"  @after-slide-change="onAfterSlideChange" @before-slide-change="onBeforeSlideChange" @last-slide="onLastSlide">
        <slide v-for="(slide, i) in slides" :key="i" :index="i" :style="'background-image: url(' + DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' + tm.tm_params.season_code + '/' + slide.course_code + '/' + slide.course_image_file_1 +'); display: block; background-repeat: no-repeat; background-size: cover; height: -webkit-fill-available;'"        
         >
              <div id="overMay"></div>
              <div class="contentArea" >
                <b-img
                  class="cardlogo"
                  v-if="slide.sponsor_logo.length > 0"
                  :src="slide.sponsor_logo"
                ></b-img>
                <div class="contentInner-area">
                  <h2 class="fullname">
                    {{slide.full_name}} 
                  </h2>
                  <h2 class="coursename">
                    {{slide.dates}}
                  </h2>
                  <b-row class="contentArea">
                    <b-col>
                      <span>
                        <p class="titleClass">Rounds</p>
                        <p class="titleText">{{slide.rounds}}</p>
                      </span>
                    </b-col>
                    <b-col>
                      <span>
                        <p class="titleClass">Prize Fund</p>
                        <p class="titleText">{{slide.total_prize_fund}}</p>
                      </span>
                    </b-col>
                  </b-row>
                  <a
                    :href="'/tournament?id=' + tm.tm_params.season_code + '&code=' + slide.code"
                    class="tournInfoBtn btn tournBtn-home"
                    >Tournament Information</a
                  >
                </div>
              </div>
        </slide>
    </carousel-3d>
    <!-- <div id="example">
      <carousel-3d @after-slide-change="onAfterSlideChange" @before-slide-change="onBeforeSlideChange" @last-slide="onLastSlide">
        <slide v-for="(slide, i) in slides" :index="i" :key="i">
          <div  v-for="(slide, i) in slidess" :index="i" :key="i">
              {{slide.full_name}}
          </div>
        </slide>
      </carousel-3d>
    </div> -->
  </div>
</template>
<script>
import apiCall from "@/services/api.js";

// import 'vueperslides/dist/vueperslides.css
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: "upComingTours",
  props: ["config", "season", "tm", 'tournamentList', 'DEFIMAGESAMS3', 'fullList'],
  components: { 
    Carousel3d,
    Slide
   },
  data() {
    return {
      // slides: 6,
      // slides: this.fullList,
      // FullListings:[]
      slides: 9,
    };
  },
  methods: {
    async getTourns() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmSeasonBase();
        this.seasonCode = first_call.data.tm_params.season_code;
        this.loading = false;
        // Second call, await for call to return
        let third_call = await apiCall.tmHomepage(this.seasonCode);
        this.tournamentList = third_call.data.tournaments.tournaments_entry;
        console.log("this.tournamentList")
        console.log(this.tournamentList)
        this.tournsshortList()
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    futureTours() {
      var data = this.tournamentList;
      return data
        .filter((slide) => slide.days_away.indexOf("-"))
        .slice(0, 6);
    },
    pastTours() {
      var data = this.tournamentList;
      return data
        .filter((slide) => slide.days_away < -1)
        .slice(-3);
    },
    async tournsshortList() {
      this.fut = this.futureTours();
      this.past = this.pastTours();
      this.slides = this.fut.concat(this.past);
    },
    scrollToElement() {
      let content = document.querySelector(".test");
      content.scrollLeft = 1000;
    },
    onAfterSlideChange(index) {
      console.log('@onAfterSlideChange Callback Triggered', 'Slide Index ' + index)
    },
    onBeforeSlideChange(index) {
      console.log('@onBeforeSlideChange Callback Triggered', 'Slide Index ' + index)
    },
    onLastSlide(index) {
      console.log('@onLastSlide Callback Triggered', 'Slide Index ' + index)
    }
  },
  async mounted() {
    this.getTourns();
    setTimeout(() => {
      this.scrollToElement();
    }, 1700);
  },
};
</script>

<style scoped>
.contentInner-area {
  padding-top: 5px;
}
::v-deep .carousel-3d-container {
  height: 436px!important;
}
::v-deep .carousel-3d-slider {
  width: 362px!important;
  height: 430px!important;
}
::v-deep .next {
  /* right: 220px; */
  text-align: right;
  color: #006737 !important;
}
::v-deep .prev {
  /* left: 220px; */
  text-align: left;
  color: #006737 !important;
}
.contentArea {
  margin: auto;
  position: relative;
  z-index: 999;
}
.titleClass {
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
  z-index: 999;
  position: relative;
}
.titleText {
  color: #fff;
  font-weight: bold;
  text-align: center;
  z-index: 999;
  position: relative;
}
h2.fullname {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  z-index: 999;
  position: relative;
}
h2.coursename {
  font-size: 18px;
  line-height: 15px;
  color: #fff;
  text-align: center;
  z-index: 999;
  position: relative;
}
/* viperSlides */
::v-deep .vueperslide.vueperslide--visible {
  background-image: linear-gradient(#006837, #003b1f);
  border-left: 1px solid grey;
  transform: skew(0deg);
  transition-duration: 2s;
}
::v-deep .vueperslide.vueperslide--visible:hover {
  background-image: linear-gradient(pink, pink);
  border-left: 1px solid grey;
  width: 450px!important;
}
::v-deep .vueperslide__title {
  color: #f2af15;
  font-weight: bold;
}
::v-deep .vueperslide__content {
  color: #fff;
  font-weight: bold;
}
/* End of viperSlides */

.accordionWrapper {
  height: 400px;
  width: 100%;
  overflow: scroll;
}
#overMay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  cursor: pointer;
}
.current.table__cell {
  background-color: #003b1f;
  width: 500px;
  background-image: none;
  /*background-image: url('/wp-content/uploads/2021/03/1249570.jpg');*/
  border-bottom: 1px solid #003b1f;
  background-repeat: no-repeat;
  background-size: cover;
}
.current.table__cell > span.service_text {
  opacity: 1;
}
.current.table__cell > .date_title_block {
  display: none;
}
.current.table__cell > span > div > img {
  height: 80px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}
.current.table__cell > span > h3.service__title {
  /*color: rgb(33 66 50) !important;*/
  color: rgb(255 255 255) !important;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 1;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > h3.service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  /* margin-bottom: 40px; */
  margin-bottom: 25px;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  /*background: white;*/
  width: 100%;
  margin-left: 0px;
  z-index: 9999;
  position: relative;
}
.current.table__cell > span > a.tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
  z-index: 9999;
  position: relative;
}
.date_title_block {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
}
.accordion__wrapper {
  height: 400px;
  width: 100%;
  overflow: scroll;
  /*background: blue;*/
}

.service__table {
  display: inline-flex;
  width: fit-content;
  height: 100%;
  justify-content: center;
}

.table__cell {
  /*transform: skew(-20deg);*/
  transform: skew(0deg);
  position: relative;
  /*display: flex;*/
  width: 300px;
  align-items: center;
  justify-content: center;
  transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: linear-gradient(#006837, #003b1f);
  border-left: 1px solid grey;
}
.table__cell:hover {
  width: 500px;
  /*background: white!important;*/
  /*background-image: url('/wp-content/uploads/2021/03/1249570.jpg');*/
  border-bottom: 1px solid #003b1f;
  background-repeat: no-repeat;
  background-size: cover;
}
.pointer-hover:hover {
  width: 500px;
  background: white !important;
  border-bottom: 1px solid #003b1f;
}
.table__cell .service__title {
  /*white-space: nowrap;*/
  transform: rotate(-0deg) translate(0, 0);
  text-align: center;
}
.table__cell:hover .service__title {
  /*color: rgb(33 66 50) !important;*/
  color: rgb(255 255 255) !important;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 9999;
  position: relative;
}
.pointer-hover:hover .service__title {
  color: rgb(33 66 50) !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
.service__title {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.cover_service__date {
  color: #f2af15;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.cover_service__title {
  color: #fff;
  text-align: center;
  z-index: 9999;
  position: relative;
}
.table__cell:hover .cover_service__date {
  display: none;
}
.pointer-hover:hover .cover_service__date {
  display: none;
}
.table__cell:hover .cover_service__title {
  display: none;
}
.pointer-hover:hover .cover_service__title {
  display: none;
}
.table__cell:hover .service__title {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #fff;
  /*background-color: rgb(255 255 255 / 30%);*/
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hover:hover .service__title {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #fff;
}
.service__date {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
  z-index: 9999;
  position: relative;
}
.table__cell:hover .service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  margin-bottom: 25px;
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hoverpointer-hover:hover .service__date {
  opacity: 1;
  transition-delay: 300ms;
  text-align: center;
  color: #f2af15;
  margin-top: 10px;
  margin-bottom: 25px;
}
.table__cell .service_text {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .service_text {
  opacity: 1;
  transition-delay: 300ms;
}
.pointer-hover:hover .service_text {
  opacity: 1;
  transition-delay: 300ms;
}
.table__cell .sliderInfo-space {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  width: 100%;
  margin-left: 0px;
  z-index: 9999;
  position: relative;
  /*background-color: rgb(255 255 255 / 30%);*/
  /*background-color: rgb(0 0 0 / 30%);*/
}
.pointer-hover:hover .sliderInfo-space {
  opacity: 1;
  transition-delay: 300ms;
  background: white;
  width: 100%;
  margin-left: 0px;
}
.cardlogo {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
  position: relative;
  z-index: 9999;
  width: 100%;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
}
.cardlogo {
  height: auto;
  margin: auto;
  /* text-align: center; */
  /* display: list-item; */
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 30px;
  /* width: 100%; */
  padding-left: 100px;
  padding-right: 100px;
}
.pointer-hover:hover img.cardlogo {
  /*width: 200px;*/
  height: 120px;
  margin: auto;
  text-align: center;
  display: list-item;
  opacity: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}
.table__cell .tournInfoBtn {
  transform: skew(0deg);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0;
}
.table__cell:hover .tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
  z-index: 9999;
  position: relative;
}
.pointer-hover:hover .tournInfoBtn {
  opacity: 1;
  transition-delay: 300ms;
  width: 400px;
}
.service_logo {
  height: 163px;
  overflow: hidden;
  z-index: 9999;
  position: relative;
}
p.tourn-small-info {
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  font-size: 10pt;
}
p.value-info-home {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.tournBtn-home {
  text-align: center;
  display: block;
  margin: auto;
  background: #93278f;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  z-index: 999;
  position: relative;
  top: 30px;
}
.tournBtn-home:hover {
  text-align: center;
  display: block;
  margin: auto;
  background: #f8635f;
  border: none;
  color: #fff !important;
  border-radius: 0;
  padding: 5px 20px;
  /* width: 100%; */
  margin-left: 20px;
  margin-right: 20px;
  z-index: 999;
  position: relative;
  top: 30px;
}
.sliderInfo-space {
  margin-bottom: 20px;
}
h3 {
  font-size: 18px;
  line-height: 25px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #24292e;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
</style>