import { render, staticRenderFns } from "./homeStats.vue?vue&type=template&id=6d567870&scoped=true"
import script from "./homeStats.vue?vue&type=script&lang=js"
export * from "./homeStats.vue?vue&type=script&lang=js"
import style0 from "./homeStats.vue?vue&type=style&index=0&id=6d567870&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d567870",
  null
  
)

export default component.exports