<template>
  <div>
    <b-row class="Desk">
      <b-col class="mobCol" md="4">
        <div class="containerBlock">
          <div class="dataTitle">{{ currentOomTitle }}</div>
          <a
            class="playertopSizes"
            :href="'/playerprofile/' + currentOom[0].playerno"
          >
            <b-img
              class="oomTopImg"
              :src="
                DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
                currentOom[0].playerno +
                '.jpg'
              "
            ></b-img>
          </a>
          <a
            :href="'/playerprofile/' + currentOom[0].playerno"
            class="playerLink"
          >
            <b-row class="bottomOom">
              <b-col lg="12">
                <span class="homePos">
                  <span class="innerPos">{{ currentOom[0].pos }}</span>
                </span>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col cols="12" lg="2" md="2" sm="2">
                    <b-img
                      class="playerFlag"
                      :src="
                        (config.VUE_APP_FLAG_URL + currentOom[0].nationality)
                          | lowercase
                      "
                    ></b-img>
                  </b-col>
                  <b-col cols="12" lg="10" md="10" sm="10">
                    <span class="infoBlock">
                      <p class="topOomName">
                        {{ currentOom[0].name }}
                      </p>
                      <p class="topOomName oomValue">
                        {{ currentOom[0].oom_value }}
                      </p>
                      <p class="tournsPlayed">
                        <span>Tournaments: </span
                        >{{ currentOom[0].tournaments_played }}
                      </p>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </a>
        </div>
      </b-col>
      <b-col class="mobCol" md="4">
        <div class="containerBlock">
          <div class="dataTitle">{{ rookieTitle }}</div>
          <a
            class="playertopSizes"
            :href="'/playerprofile/' + rookie[0].playerno"
          >
            <b-img
              class="oomTopImg"
              :src="
                DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
                rookie[0].playerno +
                '.jpg'
              "
            ></b-img>
          </a>
          <a :href="'/playerprofile/' + rookie[0].playerno" class="playerLink">
            <b-row class="bottomOom">
              <b-col lg="12">
                <span class="homePos">
                  <span class="innerPos">{{ rookie[0].pos }}</span>
                </span>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <b-col cols="12" lg="2" md="2" sm="2">
                    <b-img
                      class="playerFlag"
                      :src="
                        (config.VUE_APP_FLAG_URL + rookie[0].nationality)
                          | lowercase
                      "
                    ></b-img>
                  </b-col>
                  <b-col cols="12" lg="10" md="10" sm="10">
                    <span class="infoBlock">
                      <p class="topOomName">
                        {{ rookie[0].name }}
                      </p>
                      <p class="topOomName oomValue">
                        {{ rookie[0].oom_value }}
                      </p>
                      <p class="tournsPlayed">
                        <span>Tournaments: </span
                        >{{ rookie[0].tournaments_played }}
                      </p>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </a>
        </div>
      </b-col>
      <template>
        <b-col class="mobCol" md="4">
          <div class="containerBlock">
            <div class="dataTitle">{{ statsTitle }}</div>
            <a
              class="playertopSizes"
              :href="'/playerprofile/' + stats[0].playerno"
            >
              <b-img
                class="oomTopImg"
                :src="
                  DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
                  stats[0].playerno +
                  '.jpg'
                "
              ></b-img>
            </a>
            <a :href="'/playerprofile/' + stats[0].playerno" class="playerLink">
              <b-row class="bottomOom">
                <b-col lg="12">
                  <span class="homePos">
                    <span class="innerPos">{{ stats[0].pos }}</span>
                  </span>
                </b-col>
                <b-col lg="12">
                  <b-row>
                    <b-col cols="12" lg="2" md="2" sm="2">
                      <b-img
                        class="playerFlag"
                        :src="
                          (config.VUE_APP_FLAG_URL + stats[0].nationality)
                            | lowercase
                        "
                      ></b-img>
                    </b-col>
                    <b-col cols="12" lg="10" md="10" sm="10">
                      <span class="infoBlock">
                        <p class="topOomName">
                          {{ stats[0].name }}
                        </p>
                        <p class="topOomName oomValue">
                          {{ stats[0].oom_value }}
                        </p>
                        <p class="tournsPlayed">
                          <span>Tournaments: </span
                          >{{ stats[0].tournaments_played }}
                        </p>
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </a>
          </div>
        </b-col>
      </template>
    </b-row>
    <b-row class="Mob">
      <b-col class="mobCol" cols="12" sm="12">
        <div class="dataTitle">{{ currentOomTitle }}</div>
        <a
          class="playertopSizes"
          :href="'/playerprofile/' + currentOom[0].playerno"
        >
          <b-img
            class="oomTopImg"
            :src="
              DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
              currentOom[0].playerno +
              '.jpg'
            "
          ></b-img>
        </a>
        <b-row class="bottomOom">
          <b-col lg="12">
            <span class="homePos">
              <span class="innerPos">{{ currentOom[0].pos }}</span>
            </span>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col lg="2" md="2" sm="2" class="mobFlag">
                <b-img
                  class="playerFlag"
                  :src="
                    (config.VUE_APP_FLAG_URL + currentOom[0].nationality)
                      | lowercase
                  "
                ></b-img>
              </b-col>
              <b-col lg="10" md="10" sm="10" class="mobInfo">
                <span class="infoBlock">
                  <p class="topOomName">
                    {{ currentOom[0].name }}
                  </p>
                  <p class="topOomName oomValue">
                    {{ currentOom[0].oom_value }}
                  </p>
                  <p class="tournsPlayed">
                    <span>Tournaments: </span
                    >{{ currentOom[0].tournaments_played }}
                  </p>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mobCol" cols="12" sm="12">
        <div class="dataTitle">{{ rookieTitle }}</div>
        <a
          class="playertopSizes"
          :href="'/playerprofile/' + rookie[0].playerno"
        >
          <b-img
            class="oomTopImg"
            :src="
              DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
              rookie[0].playerno +
              '.jpg'
            "
          ></b-img>
        </a>
        <b-row class="bottomOom">
          <b-col lg="12">
            <span class="homePos">
              <span class="innerPos">{{ rookie[0].pos }}</span>
            </span>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col lg="2" md="2" sm="2" class="mobFlag">
                <b-img
                  class="playerFlag"
                  :src="
                    (config.VUE_APP_FLAG_URL + rookie[0].nationality)
                      | lowercase
                  "
                ></b-img>
              </b-col>
              <b-col lg="10" md="10" sm="10" class="mobInfo">
                <span class="infoBlock">
                  <p class="topOomName">
                    {{ rookie[0].name }}
                  </p>
                  <p class="topOomName oomValue">
                    {{ rookie[0].oom_value }}
                  </p>
                  <p class="tournsPlayed">
                    <span>Tournaments: </span>{{ rookie[0].tournaments_played }}
                  </p>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mobCol" cols="12" sm="12">
        <div class="dataTitle">{{ statsTitle }}</div>
        <a class="playertopSizes" :href="'/playerprofile/' + stats[0].playerno">
          <b-img
            class="oomTopImg"
            :src="
              DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
              stats[0].playerno +
              '.jpg'
            "
          ></b-img>
        </a>
        <b-row class="bottomOom">
          <b-col lg="12">
            <span class="homePos">
              <span class="innerPos">{{ stats[0].pos }}</span>
            </span>
          </b-col>
          <b-col lg="12">
            <b-row>
              <b-col lg="2" md="2" sm="2" class="mobFlag">
                <b-img
                  class="playerFlag"
                  :src="
                    (config.VUE_APP_FLAG_URL + stats[0].nationality) | lowercase
                  "
                ></b-img>
              </b-col>
              <b-col lg="10" md="10" sm="10" class="mobInfo">
                <span class="infoBlock">
                  <p class="topOomName">
                    {{ stats[0].name }}
                  </p>
                  <p class="topOomName oomValue">
                    {{ stats[0].oom_value }}
                  </p>
                  <p class="tournsPlayed">
                    <span>Tournaments: </span>{{ stats[0].tournaments_played }}
                  </p>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HomeStats",
  props: ["Year", "config", 'DEFIMAGESAMS3'],
  data() {
    return {
      menueLink: "menuLink",
      flag: "flag",
      currentOom: [],
      rookie: [],
      stats: [],
      currentSeason: {}
      // currentSeason: []
    };
  },

  filters: {
    lowercase: function (value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    YearSeason: function () {
      axios
      .get(
        "https://api.euro.ocs-software.com/sst/cache/lds" +
          "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.currentSeason = response.data.tm_params.season_code;
        this.currentSeason = '225S';
        this.statOne();
        this.statTwo();
        this.statThree(); 
        console.log("this.currentSeason")
        console.log(this.currentSeason)
      });
    },
    statOne: function () {
      axios
      .get(
        "https://api.euro.ocs-software.com/sst/cache/lds/" +
        this.currentSeason +
        "/" +
        this.currentSeason +
        "-stats-stats-" +
        'LC' + ".json?randomadd=" + new Date().getTime()
      )
      .then((response) => {
        this.currentOom = response.data.oom.oom_entry;
        console.log("this.currentOom")
        console.log(this.currentOom)
        this.currentOomTitle = response.data.full_name;
        console.log("this.currentOomTitle")
        console.log(this.currentOomTitle)
      });
    },
    statTwo: function () {
      axios
      .get(
        "https://api.euro.ocs-software.com/sst/cache/lds/" +
        this.currentSeason +
        "/" +
        this.currentSeason +
        "-stats-stats-" +
        'L1' + ".json?randomadd=" + new Date().getTime()
      )
      .then((response) => {
        this.rookie = response.data.oom.oom_entry;
        this.rookieTitle = response.data.full_name;
      });
    },
    statThree: function () {
      axios
      .get(
        "https://api.euro.ocs-software.com/sst/cache/lds/" +
        this.currentSeason +
        "/" +
        this.currentSeason +
        "-stats-stats-" +
        'LF' + ".json?randomadd=" + new Date().getTime()
      )
      .then((response) => {
        this.stats = response.data.oom.oom_entry;
        this.statsTitle = response.data.full_name;
      });
    }
  },
  // mounted() {
  //   this.YearSeason(),
  //   axios
  //     .get(
  //       "https://api.euro.ocs-software.com/sst/cache/lds/" +
  //         this.currentSeason +
  //         "/" +
  //         this.currentSeason +
  //         "-stats-stats-" +
  //         "ZA" + ".json?randomadd=" + new Date().getTime()
  //     )
  //     .then((response) => {
  //       this.currentOom = response.data.oom.oom_entry;
  //       this.currentOomTitle = response.data.full_name;
  //       return axios.get(
  //         "https://api.euro.ocs-software.com/sst/cache/lds/" +
  //           this.seasons +
  //           "/" +
  //           this.seasons +
  //           "-stats-stats-" +
  //           "ZE" + ".json?randomadd=" + new Date().getTime()
  //       );
  //     })
  //     .then((response) => {
  //       this.rookie = response.data.oom.oom_entry;
  //       this.rookieTitle = response.data.full_name;
  //       return axios.get(
  //         "https://api.euro.ocs-software.com/sst/cache/lds/" +
  //           this.seasons +
  //           "/" +
  //           this.seasons +
  //           "-stats-stats-" +
  //           "Z1" + ".json?randomadd=" + new Date().getTime()
  //       );
  //     })
  //     .then((response) => {
  //       this.stats = response.data.oom.oom_entry;
  //       this.statsTitle = response.data.full_name;
  //     });
  // },
  mounted() {
    this.YearSeason();
  },
};
</script>

<style scoped>
.containerBlock {
  overflow: hidden;
}
.dataTitle {
  color: #fff;
  background: #f8635f!important;
  padding: 5px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 10px 10px 0px 0px;
  padding-left: 15px;
  height: 40px;
  padding-top: 8px;
}
.infoBlock:hover {
  padding-top: 20px;
  padding-bottom: 50px;
  display: block;
  padding-left: 20px;
  text-decoration: underline;
  color: #fff;
}
.playerLink:hover > .bottomOom {
}
.sponsorImage {
  width: 100%;
}
.infoBlock {
  padding-top: 20px;
  /* padding-bottom: 30px; */
  padding-bottom: 50px;
  display: block;
  padding-left: 20px;
}
.tournsPlayed {
  font-size: 14px;
  color: #ffffff;
  font-weight: 100;
  margin-bottom: 0 !important;
  padding-right: 20px;
}
.oomValue {
  color: #f8635f !important;
  font-size: 14px;
}
span.innerPos {
  margin-top: 10px;
  display: inline-block;
  font-size: 21px;
}
.oomTopImg {
  /* border-radius: 10px 10px 0 0; */
  width: 100%;
  max-height: 217px;
}
.bottomOom {
  background-color: #93278f;
  border-radius: 0 0 0px 0px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  margin-bottom: 15px;
}
.homePos {
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: auto;
  display: block;
  background: #f8635f;
  width: 50px;
  border-radius: 40px;
  height: 50px;
  margin-top: -40px;
}
.topOom {
  float: right;
  display: -webkit-box;
}
.topOomName {
  margin-bottom: 0 !important;
  padding-right: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.playerFlag {
  width: 35px;
  height: 35px;
  background: black;
  border-radius: 30px;
  margin-top: 32px;
  margin-left: 20px;
}
::v-deep .col:nth-child(2) {
  /* transform: scale(1.13); */
  z-index: 99;
  /* margin-top: -14px;  */
}
@media only screen and (min-width: 991px) {
  .Mob {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .oomTopImg {
    max-height: initial;
    width: 100%;
  }
  ::v-deep .mobCol {
    width: 100%;
    display: block;
  }
  .bottomOom {
    width: 100%;
  }
  .Desk {
    display: none;
  }
  .tThree {
    padding: 30px 0;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .oomTopImg {
    width: 100%;
  }
  .infoBlock {
    padding-top: 20px;
    padding-bottom: 30px;
    display: block;
    padding-left: 20px;
    text-align: left;
  }
  .btn-group {
    width: 100%;
    margin-bottom: 15px;
  }
  .topThree {
    padding: 15px;
    margin: auto;
    text-align: center;
  }
  .row.Mob {
    display: -webkit-inline-box;
    overflow: scroll;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
  }
  .mobCol {
    /* width: 100%; */
    width: 350px;
    display: block;
    overflow-x: auto;
    /* white-space: nowrap; */
    /* display: block; */
    float: none;
    display: inline-block;
  }
}

@media only screen and (max-width: 500px) {
  .mobFlag {
    width: 20%;
  }
  .mobInfo {
    width: 80%;
  }
}
</style>