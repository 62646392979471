<template>
  <div class="home">
    <div>
      <Jumbo
        :config="config"
        :title="title"
        :course="pastT"
        :season="seasonCode"
        :report="pastTournreport"
        :currentReport="currentReport"
        :tm="tm"
        :pastTourns="pastTourns"
        :futureTourns="futureTourns"
        :tournamentList="tournamentList"
        :pastT="pastT"
        :head="head"
        :tmparamsMini="tmparamsMini"
        :DEFIMAGESAMS3="DEFIMAGESAMS3"
        :apiClass="apiClass"
        :multiTourns="multi_tourns"
        :mutliData="mutliData"
      />
    </div>

    <div class="newsBackground">
      <b-container>
        <!-- <b-row>
          <b-col>
            <Ads
              class="mobHide"
              :page="'home'"
              :adNum="'top'"
              :mob="false"
              :side="false"
            />
          </b-col>
          <b-col>
            <Ads
              class="mobHide"
              :page="'home'"
              :adNum="'second'"
              :mob="false"
              :side="false"
            />
          </b-col>
        </b-row> -->
      </b-container>
      <b-container>
        <h1 class="NewsHomepage-Text">Top Stories</h1>
        <InterNews  :config="config" />
      </b-container>
      <b-container>
        <b-row>
          <b-col lg="4" md="12" sm="12"></b-col>
          <b-col lg="4" md="12" sm="12">
            <div class="whiteblock">
              <b-link :to="'/news'" class="newsAll">
                <span class="whitetext">See All News Stories </span>
              </b-link>
            </div>
          </b-col>
          <b-col lg="4" md="12" sm="12">
            <div class="logoBlock">
              <span class="newsLogos">
                <div class="logowidth">
                  <a
                    href="https://www.facebook.com/sunshineladiestour/"
                    target="_blank"
                  >
                    <img
                      class="socialLogosFooter"
                      src="https://live-sstlds.ocs-software.com/wp-content/uploads/2023/12/Group-333.png"
                    />
                  </a>
                </div>
                <div class="logowidth">
                  <a
                    href="https://www.instagram.com/sunshineladiestour"
                    target="_blank"
                  >
                    <img
                      class="socialLogosFooter"
                      src="https://live-sstlds.ocs-software.com/wp-content/uploads/2023/12/Group-332.png"
                    />
                  </a>
                </div>
                <!-- <div class="logowidth">
                  <a href="https://www.youtube.com/user/TheSunshineTour" target="_blank">
                    <img class="socialLogosFooter" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/YouTube_White.png">
                  </a>
                </div> -->
                <div>
                  <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FSLadiesTour" target="_blank">
                    <img
                      class="socialLogosFooter"
                      src="https://live-sstlds.ocs-software.com/wp-content/uploads/2023/12/Group-329.png"
                    />
                  </a>
                </div>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <UpComing
        :config="config"
        :tm="tm"
        :tournamentList="tournamentList"
        :fullList="fullList"
        :DEFIMAGESAMS3="DEFIMAGESAMS3"
      />
    </div>

    <!-- <div class="middleAd-above-vid">
      <Ads
        class="mobHide"
        :page="'home'"
        :adNum="'third'"
        :mob="false"
        :side="false"
      />
    </div> -->

    <div class="oomSection">
      <b-container>
        <!-- <h1 class="orderOfMerit">Order Of Merit</h1> -->
        <RookieHome
          :Year="seasonCode"
          :config="config"
          :event_sponsor_logo="event_sponsor_logo"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </b-container>
      <b-container>
        <!-- <h1 class="orderOfMerit">Order Of Merit</h1> -->
        <OOMHome
          :Year="seasonCode"
          :config="config"
          :event_sponsor_logo="event_sponsor_logo"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </b-container>
    </div>
    <div class="oomSection statLeaderBlock">
      <b-container>
        <h1 class="orderOfMerit TitleStatLeader">Statistic Leaders</h1>
        <HomeStats
          :Year="seasonCode"
          :config="config"
          :currentOom="currentOom"
          :rookie="rookie"
          :stats="stats"
          :currentOomTitle="currentOomTitle"
          :rookieTitle="rookieTitle"
          :statsTitle="statsTitle"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </b-container>
    </div>
    <!-- <div class="theScoreBack">
      <b-container>
        <h1 class="orderOfMerit">The Score Featured Players Blog</h1>
        <ScoreNews
          :cat="'10'"
          :config="config"
          :DEFIMAGESAMS3="DEFIMAGESAMS3"
        />
      </b-container>
    </div> -->
    <div class="middleAd-above-vid">
      <!-- <span class="advertCen">ADVERT</span> -->
      <!-- <Ads class='mobHide' :page="'home'" :adNum="'third'" :mob='false' :side='false'/>   -->
    </div>
  </div>
</template>

<script>
// import Ads from "@/components/ads.vue";
import apiCall from "@/services/api.js";
// import MiniLeader from "@/components/miniLeader.vue";
// import Oom from "@/components/oom.vue";
import RookieHome from "@/components/rookieHomeSlider.vue";
import OOMHome from "@/components/oomHome.vue";
import HomeStats from "@/components/homeStats.vue";
import Jumbo from "@/components/jumbo.vue";
import InterNews from "@/components/interNews.vue";
import UpComing from "@/components/upComingTours.vue";
// import ScoreNews from "@/components/thescoreHome.vue";
export default {
  name: "home",
  props: ["season", "course", "currentReport", "title", "config", "apiClass"],
  components: {
    // MiniLeader,
    // Oom,
    Jumbo,
    InterNews,
    UpComing,
    OOMHome,
    // Ads,
    RookieHome,
    // ScoreNews,
    HomeStats
  },
  data: function() {
    return {
      homepage: true,
      tm: [],
      featHome: "true",
      wpHome: [],
      pastT: [],
      head: [],
      tournamentList: [],
      tmparamsMini: [],
      currentOom: [],
      rookie: [],
      stats: [],
      pastTourns: [],
      futureTourns: [],
      fullList: [],
    };
  },
  metaInfo() {
    return {
      title: "Ladies Sunshine Tour",
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description }
      ]
    };
  },
  methods: {
    futureTours() {
      var data = this.tournamentList;
      return data
        .filter((tourn) => tourn.days_away.indexOf("-"))
        .slice(0, 3);
    },
    pastTours() {
      var data = this.tournamentList;
      return data
        .filter((tourn) => tourn.days_away < -1)
        .slice(-3);
    },
  },
  computed: {
    pastTournreport: function(r) {
      if (this.tm.past_tourns === "") {
        r = this.currentReport;
      } else {
        if (this.title.includes("Final")) {
          r = this.currentReport;
        } else {
          r =
            "https://sunshinetour.info/tic/tmlsmon.cgi?tourn=" +
            this.pastT +
            "~season=" +
            this.season +
            "~";
          // r = this.currentReport
        }
      }
      return r;
    }
  },
  async mounted() {
    var json = "wp/v2/pages/28418";
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmSeasonBase();
      this.seasonCode = first_call.data.tm_params.season_code;
      this.multi_tourns = first_call.data.multi_tourns;
      this.mutliData = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(json);
      this.wpHome = second_call.data;
      this.loading = false;
      // Second call, await for call to return
      let third_call = await apiCall.tmHomepage(this.seasonCode);
      console.log("third_call")
      console.log(third_call)
      this.tournamentList = third_call.data.tournaments.tournaments_entry;
      this.fut = this.futureTours();
      this.past = this.pastTours();
      this.fullList = this.past.concat(this.fut);
      this.tmparamsMini = third_call.data;
      this.tm = third_call.data;
      if (
        this.tm.past_tourns != "" &&
        typeof this.tm.past_tourns.past_tourns_entry != "undefined"
      ) {
        if (!Array.isArray(this.tm.past_tourns.past_tourns_entry)) {
          this.tm.past_tourns.past_tourns_entry = [
            this.tm.past_tourns.past_tourns_entry
          ];
        }
        this.pastTourns = this.tm.past_tourns.past_tourns_entry;
      }
      if (
        this.tm.future_tourns != "" &&
        typeof this.tm.future_tourns.past_tourns_entry != "undefined"
      ) {
        if (!Array.isArray(this.tm.future_tourns.past_tourns_entry)) {
          this.tm.future_tourns.past_tourns_entry = [
            this.tm.future_tourns.past_tourns_entry
          ];
        }
        this.futureTourns = this.tm.future_tourns.past_tourns_entry;
      }
      this.pastT = third_call.data.code;
      this.head = third_call.data.full_name;
      this.DEFIMAGESAMS3 = third_call.data.tm_params.DEFIMAGESAMS3;
      console.log("this.DEFIMAGESAMS3");
      console.log(this.DEFIMAGESAMS3);
      // OOM Slider
      this.event_sponsor_logo = third_call.data.oom.event_sponsor_logo;
      // End OOM Slider
      // OOM
      this.currentOom = third_call.data.oom.oom.oom_entry;
      this.currentOomTitle = third_call.data.oom.full_name;
      // End OOM
      // RK
      this.rookie = third_call.data.rookie.oom.oom_entry;
      console.log("this.rookie");
      console.log(this.rookie);
      this.rookieTitle = third_call.data.rookie.full_name;
      // End RK
      // stats
      this.stats = third_call.data.stat.oom.oom_entry;
      this.statsTitle = third_call.data.stat.full_name;
      // End stats
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep .middleAd-above-vid {
  display: inline-block;
  width: 100%;
}
::v-deep button.btn.pastbtn.btn-secondary {
  /* background: #93278f; */
  background: #f8635f;
  border: none;
  border-radius: 10px 10px 0px 0px;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 0 0 0rem rgb(130 138 145 / 50%);
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn {
  /* background: #f8635f; */
  background: #93278f;
  border: none;
  border-radius: 10px 10px 0px 0px;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 0 0 0rem rgb(130 138 145 / 50%);
}
h1.orderOfMerit.TitleStatLeader {
  margin-bottom: 60px;
}
.statLeaderBlock {
  margin-bottom: 50px;
  margin-top: 30px !important;
}
.oomSection {
  margin-top: 60px;
}
h1.NewsHomepage-Text {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 25px;
  text-align: center;
}
h1.orderOfMerit {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 25px;
  text-align: center;
}
h1.greatnessTitle {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 25px;
  padding-top: 25px;
}
p.greatnessPara {
  color: #6c6c6c;
  font-size: 12px;
  width: 800px;
  text-align: center;
  margin: auto;
}
.greatnessSection {
  text-align: center;
  /* padding-top: 60px; */
  padding-bottom: 60px;
}
.theScoreBack {
  background-color: #f2f2f2;
  padding-top: 3em;
  padding-bottom: 4.5em;
}
span.advertCen {
  text-align: center;
  display: block;
  margin-top: 100px;
  font-weight: bold;
  font-size: 20pt;
}
span.advertCen {
  text-align: center;
}
.logowidth {
  margin-right: 10px;
}
.newsLogos {
  display: inline-flex;
  float: right;
  margin-top: 30px;
}
.socialLogosFooter {
  width: 22px;
  height: 22px;
}
span.whitetext {
  color: #fff;
  text-align: center;
  display: block;
  padding-top: 8px;
  font-size: 11px;
  font-weight: bold;
  /* font: normal normal normal 12px Barlow; */
  line-height: 19px;
  height: 35px;
}
.whiteblock {
  border: 1px solid white;
  width: 200px;
  height: 35px;
  margin: auto;
  margin-top: 30px;
  background-color: #93278f;
}
.whiteblock > a {
  text-decoration: none;
}
.whiteblock:hover {
  border: 1px solid white;
  width: 200px;
  height: 35px;
  margin: auto;
  margin-top: 30px;
  background-color: #f8635f;
}
.newsBackground {
  /* background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/GrassBackground.png);
  */
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 90px;
}
.activeBtn {
  background-color: #93278f !important;
}
.pastbtnRow {
  margin-top: 30px;
}
.pastbtn:hover {
  text-align: center;
  display: block;
  background: #93278f;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  margin-right: 20px;
}
.pastbtn {
  text-align: center;
  display: block;
  background: #f8635f;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  margin-right: 20px;
}
.middleAd-above-vid {
  /* margin-bottom: 10em;
  margin-top: 2em; */
}
.title {
  font-weight: 500;
  font-family: "gotham book", "roboto", sans-serif !important;
  color: #026034;
  font-size: 23pt;
  margin-top: 1em;
  line-height: 1.1;
  /* letter-spacing: 2px; */
}
.Oom {
  margin-top: 100px !important;
}
.report {
  padding: 25px;
  border-radius: 20px;
  /* background-color: white; */
}

::v-deep .nav-tabs {
  padding-bottom: 0em;
  border: 0;
  background: #f8635f;
  border-radius: 10px 10px 0px 0px;
  margin: auto;
  width: 100%;
  display: block;
}
::v-deep .nav-tabs .nav-link.active {
  border-radius: 10px;
  /* background-color: transparent !important; */
  color: #fff !important;
  padding: 5px 20px;
  font-weight: bold;
  text-align: center;
}
::v-deep .nav-tabs .nav-link {
  border-radius: 10px;
  /* background-color: transparent !important; */
  color: #93278f !important;
  padding: 5px 20px;
  border-color: transparent !important;
  font-weight: bold;
}
.leadOomHead {
  color: #026034;
  font-weight: 700;
  font-size: 20pt;
  padding-top: 50px;
  line-height: 25px;
  margin-bottom: -30px;
}
.secTitle {
  color: #026034;
  font-weight: 700;
  font-size: 20pt;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 1em;
}
.sepPad {
  padding: 50px 0 0 0;
}
::v-deep .slider > p > iframe {
  width: 100%;
  margin-bottom: 50px;
  background-color: black;
}

@media only screen and (max-width: 480px) {
  .bottomAdBanner {
    padding: 15px 0;
  }
  ::v-deep .main {
    overflow: scroll;
  }
}
@media only screen and (max-width: 500px) {
  .report {
    padding: 25px;
    border-radius: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* background-color: white; */
  }
  ::v-deep th {
    font-size: 11px;
  }
}
@media only screen and (max-width: 768px) {
  h1.orderOfMerit.TitleStatLeader {
    margin-bottom: 30px;
  }
  span.whitetext {
    color: #fff;
    text-align: center;
    display: block;
    padding-top: 8px;
    font-size: 11px;
    font-weight: bold;
    /* font: normal normal normal 12px Barlow; */
    line-height: 19px;
    height: 37px;
  }
  .mobHide {
    display: none;
  }
  .leadOomHead {
    margin-bottom: 0px;
  }
  ::v-deep .nav-tabs {
    padding: 0em 0;
  }
  ::v-deep .table__cell {
    transform: skew(0deg);
    position: relative;
    width: 430px;
    align-items: center;
    justify-content: center;
    transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
    background-image: linear-gradient(#93278f, #003b1f);
    border-left: 1px solid grey;
  }
  ::v-deep .current.table__cell > span > a.tournInfoBtn {
    opacity: 1;
    transition-delay: 300ms;
    width: 400px;
    z-index: 9999;
    position: relative;
    top: -15px;
  }
}
@media only screen and (max-width: 990px) {
  button.btn.pastbtn.btn-secondary {
    border-radius: 0px;
  }
  button.btn.pastbtn.btn-secondary.activeBtn {
    border-radius: 0px;
  }
  .pastbtnRow[data-v-7eb2bc79] {
    margin-top: 30px;
    display: block;
  }
  .pastbtn {
    margin-top: 0;
    margin-bottom: 0em;
    margin-right: 0px;
  }
  ::v-deep .FullListingBtn > a {
    color: #fff !important;
    text-decoration: none;
    font-size: 12px;
    padding-left: 0px;
  }
  ::v-deep .leaderboardPosHome.container {
    margin-top: -70px;
    z-index: 999;
    position: relative;
  }
  ::v-deep p.greatnessPara {
    color: #6c6c6c;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: auto;
  }
  .whiteblock {
    width: 100%;
  }
  .newsLogos {
    float: none;
  }
  .logoBlock {
    text-align: center;
  }
}

@media only screen and (min-width: 991px) {
  .leaderboardPosHome {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  ::v-deep img.img-fluid.w-100.d-block {
    /* display: none!important; */
    /* height: 405px; */
  }
}
</style>
